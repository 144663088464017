import {
  CURRENT_USER_REQUEST_ERROR,
  CURRENT_USER_REQUEST_SUCCESS,
  CURRENT_USER_FETCHING,

  PLAY_ERROR,
  PLAY_SUCCESS,
  IS_PLAYING,

  SELECT_ERROR,
  SELECT_SUCCESS,
  IS_SELECTING,
} from './actions';

const initialState = {
  loading: false,
  login: false,
  user: {},
  prices: {},
  jackpots: [],
  isPlaying: false,
  isSelecting: false,
  rule: '',
};

export default function currentUserReducer(state = initialState,  { type, payload } = action) {
  switch (type) {
    // USER
    case CURRENT_USER_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        login: true,
        user: payload.user,
        prices: payload.prices,
        rule: payload.rule,
        jackpots: payload.jackpots,
        errorGlobal: '',
      };
      break;
    case CURRENT_USER_REQUEST_ERROR:
      return {
        ...state,
        loading: false,
        errorGlobal: 'Không có thông tin user',
      };
      break;
    case CURRENT_USER_FETCHING:
      return {
        ...state,
        loading: true
      };
      break;

    // PLAY
    case PLAY_SUCCESS:
      return {
        ...state,
        isPlaying: false,
        user: payload.user,
        prices: payload.prices,
      }
      break;
    case PLAY_ERROR:
      return {
        ...state,
        isPlaying: false,
      }
      break;
    case IS_PLAYING:
      return {
        ...state,
        isPlaying: true
      }
      break;

    // SELECT
    case SELECT_SUCCESS:
      return {
        ...state,
        isSelecting: false,
        user: payload.user,
      }
      break;
    case SELECT_ERROR:
      return {
        ...state,
        isSelecting: false,
      }
      break;
    case IS_SELECTING:
      return {
        ...state,
        isSelecting: true
      }
      break;

    // DEFAULT
    default:
      return state;
  }
}
